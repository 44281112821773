import React from "react";
import Store from "./src/context/store";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
} from "@apollo/client";
import { CookiesProvider } from "react-cookie";

const createApolloClient = () => {
  return new ApolloClient({
    link: new HttpLink({
      uri: process.env.API_ENDPOINT,
    }),
    cache: new InMemoryCache(),
  });
};

export const wrapRootElement = ({ element }) => {
  const client = createApolloClient();
  return (
    <CookiesProvider>
      <ApolloProvider client={client}>
        <Store>{element}</Store>
      </ApolloProvider>
    </CookiesProvider>
  );
};
