export interface State {
  theme: "light" | "dark";
}

export const SET_THEME = "SET_THEME";

interface SetThemeAction {
  type: typeof SET_THEME;
  payload: "light" | "dark";
}

export type ActionTypes = SetThemeAction;
