import React, { createContext, useReducer, Dispatch } from "react";
import Reducer from "./reducer";
import { ActionTypes, State } from "./types";

const initialState: State = {
  theme: "light",
};

interface StoreProps {
  children: React.ReactNode;
}

const Store = ({ children }: StoreProps) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export const Context = createContext({
  state: initialState,
  dispatch: new Object() as Dispatch<ActionTypes>,
});
export default Store;
