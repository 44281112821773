import React, { useContext, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import CookieConsent from "react-cookie-consent";
import Header from "../components/header";
import "./layout.scss";
import { Context } from "../context/store";
import Transition from "../components/transition";

interface LayoutProps {
  children: React.ReactNode;
  location: any;
}

const Layout = ({ children, location }: LayoutProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { state } = useContext(Context);

  useEffect(() => {
    document.body.style.backgroundColor =
      state.theme === "light" ? "white" : "hsl(210, 24%, 16%)";
    document.body.style.color = state.theme === "light" ? "black" : "white";
  }, [state]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <Transition location={location}>
        <main style={{ height: "100%" }}>{children}</main>
      </Transition>
      <footer className="footer">
        <span>© 2021 Sam Ware</span>
        <span>&nbsp;|&nbsp;</span>
        <Link className="privacy-link" to={"/privacy"}>
          Privacy
        </Link>
      </footer>
      <CookieConsent
        location="bottom"
        style={{
          backgroundColor: "rgb(104, 115, 125, .95)",
          fontFamily: '"Lato", sans-serif',
        }}
        buttonStyle={{ backgroundColor: "#2487eb", color: "white" }}
        contentClasses="cookie-consent-content"
        buttonClasses="cookie-consent-button"
      >
        This website uses cookies to enhance the user experience. Read more
        about it{" "}
        <Link to={"/privacy"} style={{ color: "inherit" }}>
          here
        </Link>
      </CookieConsent>
    </div>
  );
};

export default Layout;
