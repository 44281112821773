import { State, ActionTypes, SET_THEME } from "./types";

const Reducer = (state: State, action: ActionTypes): State => {
  switch (action.type) {
    case SET_THEME:
      return { ...state, theme: action.payload };
    default:
      return state;
  }
};

export default Reducer;
