import { Link } from "gatsby";
import React, { useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils } from "@fortawesome/free-solid-svg-icons";
import { faMoon } from "@fortawesome/free-regular-svg-icons";

import "./header.scss";
import { Context } from "../context/store";
import { SET_THEME } from "../context/types";
import { useCookies } from "react-cookie";

interface HeaderProps {
  siteTitle: string;
}

const Header = ({ siteTitle }: HeaderProps) => {
  const { state, dispatch } = useContext(Context);
  const [cookies, setCookie] = useCookies();

  useEffect(() => {
    if (cookies.theme) {
      dispatch({
        type: SET_THEME,
        payload: cookies.theme,
      });
    }
  }, []);

  const onThemeChange = () => {
    dispatch({
      type: SET_THEME,
      payload: state.theme === "light" ? "dark" : "light",
    });
    setCookie("theme", state.theme === "light" ? "dark" : "light", {
      path: "/",
    });
  };

  return (
    <>
      <header
        style={{
          backgroundColor:
            state.theme === "light" ? "white" : "hsl(210, 24%, 16%)",
        }}
      >
        <div className="container">
          <Link
            style={{ color: state.theme === "light" ? "black" : "white" }}
            to="/"
            title="logo"
          >
            <FontAwesomeIcon
              icon={faUtensils}
              style={{ width: "2rem", height: "2rem" }}
            />
          </Link>
          <h1>
            <Link
              to="/"
              style={{
                color: state.theme === "light" ? "black" : "white",
                textDecoration: `none`,
              }}
            >
              {siteTitle}
            </Link>
          </h1>
          <div className="right-side-header">
            <FontAwesomeIcon
              icon={faMoon}
              onClick={onThemeChange}
              style={{
                color: state.theme === "light" ? "black" : "white",
                width: "1.25rem",
                height: "1.25rem",
                float: "right",
                cursor: "pointer",
              }}
            />
          </div>
          {/* <HamburgerButton
            open={isModalVisible}
            onClick={value => setModalVisible(value)}
          /> */}
        </div>
      </header>
      {/* <Modal visible={isModalVisible} /> */}
    </>
  );
};

export default Header;
